import React, { useState } from 'react';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Header from './components/Header';
import HomePage from './components/HomePage';
import MenuPage from './components/MenuPage';
import GamesPage from './components/GamesPage';
import Footer from './components/Footer';
import GameShip from './components/animations/GameShip';
import PrivacyPolicyPage from './components/PrivacyPolicyPage';

export interface IPageProps
{
    onLoad: () => void;
}

const App: React.FC = () => {

    const [animationDone, setAnimationDone] = useState<boolean>(false);
    const [page, setPage] = useState<string>("");
    return (
        <>
            <div className={`app app--${page}`} style={{opacity: animationDone? 1 : 0 }}>
                <BrowserRouter>
                    <Header />
                    <Routes>
                        <Route path="/" element={<HomePage onLoad={() => setPage("home")}/>} />
                        <Route path="/games" element={<GamesPage onLoad={() => setPage("games")}/>} />
                        <Route path="/menu" element={<MenuPage onLoad={() => setPage("menu")}/>} />
                        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                    </Routes>
                    <Footer />
                </BrowserRouter>
            </div>
            <GameShip onAnimationDone={() => setAnimationDone(true)} animationDone={animationDone}/>
        </>
    );
};

export default App;
