import React from "react";


const PrivacyPolicyPage: React.FC = () =>
{
    return (
        <div className="content">
            
<h1>Privacy Policy</h1>

<p>
Last Updated: 19/11/2024
</p>

<h3>Introduction</h3>
<p>
Pub Kafic ("we," "us," or "our") is committed to protecting and respecting your privacy. This Privacy Policy outlines the types of information we may collect from visitors to our website https://pubkafic.com (the "Site") and explains how we handle that information.
</p>

<h3>Information We Collect</h3>
<p>
Our website is primarily informational and does not require users to create an account, log in, or provide any personal details. As such, we do not actively collect any personally identifiable information (PII) about visitors.

However, there may be some technical information collected automatically by our website or hosting service. This information may include:

IP address: The numerical address used to identify your device when accessing the internet.
Browser and device information: Information about the browser, operating system, and device you are using to view our website.
Cookies and tracking technologies: Our website may use cookies to enhance user experience, but these cookies do not track personally identifiable information. You can manage or disable cookies through your browser settings.
How We Use Your Information
Since we do not actively collect any personal data, the information we receive (such as IP address, browser details) is used for purposes such as:

Website analytics (e.g., understanding how visitors interact with the site).
Improving the performance and functionality of the website.
</p>

<h3>Third-Party Services</h3>
<p>
Our website may include links to third-party websites or services. We are not responsible for the privacy practices or content of these external sites. We encourage you to review their privacy policies before providing any personal information to them.
</p>

<h3>
Data Security</h3>
<p>
While we do not collect personal information, we are committed to ensuring the security of any data processed in the course of operating our website. We implement reasonable technical and organizational measures to protect against unauthorized access, alteration, or destruction of information.
</p>

<h3>Your Rights and Choices</h3>
<p>
Since we do not collect personal information, you are not required to provide any personal data when visiting our website. If you are located in a region with privacy laws (e.g., the EU's GDPR or California's CCPA), you may have additional rights related to the data we collect. However, as our site does not collect personal data, most of these rights do not apply.

If you have any questions about our privacy practices, please contact us at info@pubkafic.com.
</p>
<h3>
Changes to This Privacy Policy
</h3>
<p>
We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated "Last Updated" date. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your privacy.
</p>
<h3>
Contact Information
</h3>
<p>
If you have any questions or concerns about this Privacy Policy, please contact us at:
Kafic
info@pubkafic.com
</p>
        </div>
    );
};

export default PrivacyPolicyPage;